body {
  height: 100%;
}

h1 {
  font-size: 2.3em;
}

.root-layout {
  margin: 0;
  //height: 100vh;
  .ant-layout {
    min-height: 100vh;
  }
}

.sidebar-layout .ant-card {
  margin-top: 1em;

  .username {
    margin-left: 0.5em;
    text-transform: capitalize;
    display: inline-block;
  }
}

.etb-card {
  width: 25%;
  padding: 16px;
}

.excluded-row {
  background-color: #eee;
}


@media screen and (max-width: 1300px) {
  .etb-card {
    width: 33%;
  }
}

@media screen and (max-width: 1050px) {
  .etb-card {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .etb-card {
    width: 100%;
  }
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0;
}

.contenu-tab {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.contenu-tab.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.site-tag-plus {
  cursor: pointer;
  background: #fff;
  border-style: dashed;
}

.my_popover {
  width: 300px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.cursor-p {
  cursor: pointer;
}

.hover-scale:hover {
  transform: scale(1.1);
}

.b-white {
  background-color: #fff;
}

.no-select {
  user-select: none;
}

// font
.font-15 {
  font-size: 1.5em;
}

.font-08 {
  font-size: 0.8em;
}

.bold {
  font-weight: bold;
}

//Margin
.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.m-left-5 {
  margin-left: 5px;
}

.m-left-10 {
  margin-left: 10px;
}

.m-left-15 {
  margin-left: 15px;
}

.m-right-15 {
  margin-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.m-topbot-20 {
  margin: 20px 0;
}

//padding
.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px
}

.padding-side-5 {
  padding: 0 5px;
}

.padding-topbot-10 {
  padding: 10px 0;
}

.pt-10 {
  padding-top: 10px;
}

//FlexBox
.block {
  display: block;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.end {
  justify-content: flex-end;
}

.content-start {
  align-content: flex-start;
}

//Width
.w-100per {
  width: 100%;
}

.w-90per {
  width: 90%;
}

.w-80per {
  width: 80%;
}

.w-70per {
  width: 70%;
}

.w-60per {
  width: 60%;
}

.w-50per {
  width: 50%;
}

.w-40per {
  width: 40%;
}

.w-30per {
  width: 30%;
}

.w-20per {
  width: 20%;
}

.w-15per {
  width: 15%;
}

.w-10per {
  width: 10%;
}

.w-5per {
  width: 5%;
}

.w-80vw {
  width: 80vw;
}

.w-300px {
  width: 300px;
}

// Height
.min-height-400 {
  min-height: 400px;
}

.h-100per {
  height: 100%;
}

//border
.border1 {
  border: 1px solid #ddd;
}

.b-radius {
  border-radius: 5px;
}

// Text
.text-center {
  text-align: center;
}

.through {
  text-decoration: line-through;
}

// color
.danger {
  color: red;
}

// position

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

//  Page de connexion
#login-form {
  margin-top: 100px;
  width: 100%;

  h2 {
    text-align: center;
  }

  .login-form {
    max-width: 300px;
    margin: auto;
  }

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
  }
}

// image uploader
.avatar-uploader > .ant-upload {
  width: 300px;
}


// datepicker antd
.ant-picker-dropdown {
  z-index: 10000;
}

// Tabs
.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  background-color: #fff;
}

// Redirection
.url-redir {
  transition: 0.3s;
}

.url-redir:hover {
  background-color: #ddd;
}

.url-redir.success:hover {
  background-color: #7cb342;
}

.success {
  background-color: #aed581;
}

.MyHighlightClass {
  background-color: yellow;
}

// trello-box
.trello-box {
  min-width: 250px;
  min-height: 150px;
  background-color: #ddd;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;

  .trello-box-title {
    font-weight: bold;
    padding: 10px 0;
  }

  .trello-card {
    background-color: #fff;
    padding: 5px;
    margin: 5px 0;
    font-size: 0.9em;
    border-radius: 2.5px;

    .trello-card-title {
      font-weight: bold;
      padding: 5px 0;
    }
  }

  .trello-card-page {
    padding: 5px;
    border: 1px dashed black;
    border-radius: 2.5px;
    margin: 5px 0;
  }
}

.my-tag {
  padding: 0 20px;
  margin: 5px;
  border-radius: 1px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.my-tag.tag-edit {
  border: 1px solid #ddd;
  padding: 0;
}

.select-color {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin: 4px;
}


// Dashboard
.table {
  min-width: 750px;

  .row {
    display: flex;

    .col {
      padding: 10px 20px;
      background-color: #eee;
      border: 1px solid white;
      text-align: center;
    }

    .col.head {
      background-color: #ccc;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
    }

    .col-name {
      text-align: left;
      width: 350px;
    }

    .col-tag {
      width: 150px;
      color: #fff;
    }

    .col-date {
      width: 200px;
    }

    .col-etb {
      width: 150px;
    }
  }

  .row-comment {
    display: flex;

    .col-comment {
      padding: 10px 20px;
      background-color: #eee;
      border: 1px solid white;
      text-align: center;
    }

    .col-comment.head {
      background-color: #ccc;
      text-align: center;
    }

    .col-message {
      width: 300px;
      text-align: left;
    }

    .col-user {
      width: 150px;
    }

    .col-date {
      width: 150px;
      text-align: center;
    }

    .col-etb {
      width: 150px;
      text-align: center;
    }
  }

  .row-has-more {
    background-color: #eee;
    text-align: center;
    padding: 10px 0;
  }

  .row-has-more.message {
    width: 750px;
  }

  .empty {
    background-color: #eee;
    text-align: center;
    padding: 30px 0;
    width: 850px;
  }

  .empty.message {
    width: 750px;
  }
}

// warning span
.warning {
  top: 0;
  left: 5px;
  font-size: 0.8em;
  color: red;
  font-weight: bold;
}

.warning.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#step-two-redir {
  .ant-collapse-content {
    overflow: auto;
  }
}

#canva {
  .selected {
    border: 1px dashed #3ce2e8;
  }

  .btn {
    opacity: 1;
  }

  .btn:hover
}

.keyword-selected {
  border: 1px solid orange;
}


.selected-key {
  border: 1px solid #1890ff;
}
